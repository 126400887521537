body,
input,
select {
  font-family: var(--font-family-main), "Bib's Sans Bold", "Lucida Grande", Sans-Serif;
}

body {
  background-color: var(--color-background);
}

.background-primary {
  background-color: var(--color-background-primary);
}

.background-fidelity {
  background-color: var(--color-fidelity);
}

.background-cart {
  background-color: var(--color-background-cart);
}

.background-summary {
  color: var(--color-text-flavor-list);
  background-color: var(--color-background);
}

/* HELPS */

.no-image {
  height: 99px;
  width: 190px;
  margin-left: 21px;
  background: var(--no-image);
  background-size: contain;
  background-repeat: no-repeat;
}

.no-image-summary {
  height: 70px;
  width: 140px;
  background: var(--no-image);
  background-size: contain;
  background-repeat: no-repeat;
}

.no-image-combo {
  height: 70px;
  width: 110px;
  background: var(--no-image);
  background-size: contain;
  background-repeat: no-repeat;
}
/* END HELPS */

/* BUTTONS */
.wrapper-button {
  margin: 66px 0 80px;
  padding: 0 70px;
}

button {
  font-size: 2.0rem;
  font-family: var(--font-family-main), "Bib's Sans Bold", "Lucida Grande", Sans-Serif;
  font-style: normal;
  font-weight: 700;
  border: 0;
  padding: 1em 0.8em;
  border-radius: 999px;
}

.btnnewrequest {
  width: 360px !important;
  height: 126px !important;
}

button.wide {
  padding: 1em 1.8em;
}

.bannerTelaReduzida {
  background-image: url("./assets/banner-tela-reduzida.jpg");
  background-size: 100%;
  background-position: center;
}

.bannerTelaReduzidaDefault {
  background-image: url("./assets/banner-tela-reduzida-default.png");
  background-size: 100%;
  background-position: center;
}

.bannerTelaReduzidaOff{
  display: none;
}

.button-backstep {
  display: inline-block;
  color: var(--color-title-category);
  text-align: center;
  width: 100%;
  border-radius: 20px;
  position: relative;
  background-color: var(--color-background-light);
  outline: none;
}

.button-primary {
  color: var(--color-title-category);
  background-color: var(--color-title-secondary);
  outline: none;
}

.button-primary-qrwarn {
  width: auto !important;
}

.button-secondary {
  color: var(--color-background-primary);
  background-color: var(--color-fidelity);
}

.button-outline-primary {
  outline: none;
  color: var(--color-title-secondary);
  border: 2px solid var(--color-title-secondary) !important;
  background-color: transparent;
}

.button-outline-backstep {
  color: var(--color-title-category);
  border: 2px solid var(--color-title-secondary) !important;
  background-color: transparent;
  outline: none;
}

.button--highlight {
  width: 360px;
  padding: 1.6em 0;
}

.button-buttonAddCar {
  background-color: var(--color-title-secondary) !important;
}

.button--jump {
  padding: 0.8em 0;
}

.button--clear {
  outline: none;
  border-radius: 20px;
  color: var(--color-background-progress-bar);
  font-size: 1.25rem;
  width: 138px;
  margin-right: 40px;
  border: 0.1em solid var(--color-background-progress-bar);
  background-color: transparent;
}

.button-close {
  text-transform: none;
  border: none;
  padding: 0.3em 0.8em;
  border-radius: 0;
  background-color: transparent;
  outline: none;
  width: 810px;
  height: 207px;
}

.finalizar {
  outline: none;
  width: 400px;
}

.button-withdrawn {
  width: 478px !important;
  padding: 1em 0.5em;
}

.button--block {
  width: 80%;
}

.redeem-coupon-action {
  border-radius: 63px;
  background-color: var(--color-title-secondary);
  display: flex;
  width: 90%;
  margin: 20px auto 0;
  height: 70px;
}

.resgateCupom {
  background: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-content: center;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  color: var(--color-background-primary);
  outline: none;
}

.resgate {
  margin-left: 20px;
}

button[disabled] {
  outline: none;
  opacity: 0.6;
}

.background-cart button :not(.button-clear) {
  font-size: 2.188rem;
}

.warn-container {
  height: 70px;
  margin: 0.3em 0 0;
}

.warn-container .warn {
  margin: 0;
  margin: 0.3em 0 0;
}

.logoLinx {
  position: fixed;
  margin-top: 5px;
  margin-left: 95%;
}

.lnr-eye {
  color: var(--color-fidelity);
  font-size: 48px;
  position: fixed;
  margin-top: 132px;;
  margin-left: 66%;
}

.lnr-eyeclose {
  color: var(--color-text-category);
  font-size: 48px;
  position: fixed;
  margin-top: 132px;;
  margin-left: 66%;
}

.divInput{
  display: inline-flex;
}

.warn {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  color: var(--color-background-light);
  font-size: 2rem;
  text-align: left;
  margin: 0.3em 0;
  padding: 0.5em;
  border-radius: 1.25rem;
  background-color: rgba(0, 0, 0, 0.6);
}

.card {
  border-radius: 0.7em;
  box-shadow: -6px -6px 30px #ffffff45, 6px 6px 7px var(--color-background-card-primary);
  background: var(--color-background-card-primary);
  /*background: linear-gradient(
    323deg,
    var(--color-background-card-secondary)(199, 5, 18, 1) 0%,
    var(--color-background-card-primary)(229, 10, 25, 1) 100%
  );*/
}

.card-img {
  width: 100%;
}

.pager-default {
  width: 765px !important;
}

.card-title {
  font-size: 2.363rem;
}

.box {
  display: grid;
  margin: 6.25em 0;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 5em;
}

.box.withdraw {
  margin-top: 15em;
}

/* END BUTTONS */

/* NAV, CONTENT */

.listCategory-item {
  box-sizing: border-box;
  color: var(--color-text-category);
  font-size: 1.1rem !important;
  text-transform: uppercase;
  width: 100%;
  padding: 1.18em 0;
  margin: 0.1em 0;
  border-radius: 300px 0px 0px 300px;
  position: relative;
  transition: all 0.2s ease;
}

.listCategory-item--active {
  font-weight: 700;
  width: 100%;
  background-color: var(--color-background);
  border-radius: 35px 0px 0px 35px;
}

.listCategory-item img {
  height: 96px;
  width: 130px;
}

.listCategory-item-name {
  max-width: 200px;
  margin: 0 auto;
}

.ui-border {
  content: "";
  height: 113%;
  width: 30px;
  position: absolute;
  top: -11px;
  right: 0;
  border-radius: 10px 0 0 10px;
}

.category-title,
.category-subTitle,
.groupSales-item {
  font-family: var(--font-family-main), "Bib's Sans Bold", "Lucida Grande", Sans-Serif;
  text-align: center;
}

.groupSales-item-selected {
  border: 2px solid var(--color-background-cart);
  border-radius: 20px;
}

.category-title {
  color: var(--color-title-category);
  font-size: 3.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
}

.category-subTitle {
  color: var(--color-subtitle);
  font-size: 2rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.groupSales-item {
  color: var(--color-text-category);
  /*text-transform: uppercase;*/
  font-size: 23px;
  font-weight: 400;
  margin: 0;
  margin-top: 6px;
}

.groupSales-item img,
.groupSales-item .no-image {
  width: 270px;
  height: 180px;
  padding: 2em 1em;
  border-radius: 1.5em;
  box-shadow: -6px -6px 8px var(--color-background-light), 6px 6px 20px #d8d2d2;
  background: var(--color-box-shadow-primary);
}

.groupSales-item .no-image {
  line-height: 190px;
  width: 270px;
  height: 180px;
  margin-left: 25px;
  background: var(--no-image) no-repeat 50%;
  background-size: contain;
}

.groupSales-item figcaption {
  width: 80%;
  margin: 1em auto 5px;
}

.groupSales-price {
  color: var(--color-title-category);
  font-weight: 700;
  margin: 0;
}

.badge-content {
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
  margin-top: 6px;
  margin-bottom: 10px;
}

.discountPaymentReshop {
  font-size: 18px;
  left: 17%;
  margin-top: 90px;
  position: absolute;
}

.discountPaymentReshopValue {
  color: var(--color-title-secondary);
}

.badge-content:last-child {
  margin-bottom: 50px;
}

.badge {
  height: 42px;
  width: 42px;
  display: flex;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 1.5rem;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  align-content: center;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
}

.badge-invisible {
  transform: scale(0) translate(50%, -50%);
}

.badge-top-right {
  top: 0;
  right: 0;
  transform-origin: 100% 0%;
}

.badge-color-primary {
  background-color: var(--color-background-cart);
  color: var(--color-background-light);
}

/* END NAV, CONTENT */

/* CART */
.cart-details .order * {
  margin: 0;
}

.cart-details .order-resume {
  color: var(--color-title-secondary);
  font-size: 1.5rem;
  font-weight: 100;
  text-transform: uppercase;
  padding-top: 10px;
}

.order {
  margin-left: -10px;
  margin-top: 15px;
}

.order-resume strong {
  font-weight: 600;
}

.cart-details .order-total {
  color: var(--color-background-progress-bar);
  font-size: 2.5rem;
  font-weight: 700;
}

.cart-summary {
  text-align: center;
  width: 861px;
  position: fixed;
  bottom: 8.35%;
  right: 0px;
}

.cart-summary--active .cart-summary-items {
  max-height: 42vh;
  height: 42vh;
  padding: 2em;
  margin-top: -1em;
  /* overflow-y: auto; */
}

.cart-summary--active .cart-summary-title {
  padding: 1em 2em 2em;
}

.cart-summary-title {
  padding: 1em 2em 1.5em;
  border-radius: 1.5em 1.5em 0 0;
  background-color: var(--color-title-secondary);
}

.cart-summary-items {
  overflow-y: hidden;
  max-height: 0;
  padding: 0;
  margin: 0;
  border-radius: 1.5em 1.5em 0 0;
  position: relative;
  background-color: var(--color-background);
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1) !important;
}

.cart-summary-items .summary {
  min-height: auto;
  padding: 0;
  margin-top: 0;
  background-color: transparent;
}

.cart-summary-items .summary button {
  outline: none;
  color: var(--color-background-cart);
  box-shadow: 2px 3px 5px #ccc;
  background-color: var(--color-background-light);
}

.trash .far {
  font-size: 30px;
  position: relative;
  left: 4.5px;
}

.cart-icon {
  font-size: 30px;
  color: var(--color-background-primary);
}

.cart-summary-items .summary li {
  grid-template-columns: 3fr 2fr 1fr;
  border-color: var(--color-background-light);
}

.cart-summary-items .summary-image,
.cart-summary-items .no-image {
  height: 80px;
  width: 80px;
}

.empty-cart {
  font-size: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 10vh);
}

.empty-cart img {
  height: auto;
  width: 50%;
}

/* END CART */

/* ORDER SUMMARY */
.content-wrapper {
  box-sizing: border-box;
  color: var(--color-background-progress-bar);
  text-align: center;
  padding: 4em 3em 0;
}

.content-wrapper.full {
  height: 100vh;
}

.content-wrapper .title {
  font-size: 3.75rem;
  font-weight: 700;
  word-wrap: break-word;
  margin: 1.917em 0 0.2em;
  height: 60px;
}

.content-wrapper .subtitle {
  color: var(--color-title-secondary);
  font-size: 2rem;
  font-weight: 400;
  margin: 0 0 1em;
}

.summary {
  padding: 2em 1.5em 6em;
  margin-top: 6.25em;
  margin-bottom: 0;
  border-radius: 1.25em;
  max-height: 43vh;
  min-height: 43vh;
  overflow-y: auto;
}

.summary li {
  border-bottom: 2px solid #f3f1f1;
}

.summary-image {
  height: 70px;
  width: 140px;
  font-weight: 100;
  border-radius: 20px;
}

.imageProductVo {
  height: 90px;
  width: 180px;
  font-weight: 100;
  border-radius: 20px;
}

.summary-item {
  font-size: 1.25rem;
  text-align: left;
  text-transform: uppercase;
}

.summary-item p {
  margin: 10px 0 0 0;
}

.no-image.summary-image {
  line-height: 5em;
}

.summary-item-details {
  font-size: 0.813rem;
  margin: 0 0.3em;
}

.summary-item-details.true {
  display: inline-block;
  color: var(--color-additem);
  padding: 1em;
  margin: 0.3em;
  border-radius: 2em;
  background-color: var(--color-background-light);
}

.summary-item-details.undefined + .summary-item-details.undefined::before {
  content: "•";
  margin: 0 0.5em 0 0.1em;
}

.summary-action button {
  outline: none;
  color: var(--color-background-light);
  /* padding: .3em 1em; */
  padding: 0.1em 0.7em;
  background-color: var(--color-background-cart);
  border-radius: 20px;
}

.summary-action .quantity {
  display: inline-block;
  font-size: 2.375rem;
  font-weight: 700;
  min-width: 60px;
  padding: 0 0.3em;
}

.subTotalClassStyle {
  color: #ba849f !important;
}

.cupomClassStyle {
  color: #ae666f !important;
}

.totalClassStyle {
  color: var(--color-total-price-secondary) !important;
}

.summary-footer {
  color: var(--color-title-secondary);
  font-size: 2.513rem;
  text-align: center;
  margin-top: 4px;
  padding: 0.5em;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  background-color: var(--color-keyboard-input);
}

.notPoints {
  margin-top: -10px !important
}

.summary-footer strong {
  color: var(--color-title-category);
}

.summary-footer.display-flex {
  display: flex;
}

.summary-footer.flex-column {
  flex-direction: column;
}

.summary-footer.display-flex .flex-item {
  flex-grow: 1;
  align-self: center;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.summary-footer.display-flex .flex-item.discount-displayed-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.summary-footer.display-flex .flex-item-points {
  font-size: 2rem;
}

.summary-footer.display-flex .flex-item-discounts {
  font-size: 2rem;
}

.summary .total {
  color: var(--color-background-cart);
  font-size: 1.5625rem;
  font-weight: 600;
}
/* END ORDER SUMMARY */

/* WITHDRAWN */
.withdraw-images img {
  display: inline-block;
  height: 280px;
  width: 320px;
}

.fa-arrow-alt-up {
  color: var(--color-background-primary);
  font-size: 20px;
}

.fa-arrow-alt-down {
  color: var(--color-background-primary);
  font-size: 20px;
}

/* END WITHDRAWN */

/* NumericKeypad */
.keyboard {
  width: 80%;
  margin: 80px auto;
}
.keyboardAlfaNumeric {
  width: 100% !important;
  margin: 130px auto;
  margin-top: 10px !important;
  /* margin-top: 222px !important; */
}

.keyboard input {
  box-sizing: border-box;
  font-size: 65px;
  text-align: center;
  height: 9.563rem;
  width: 100%;
  padding: 1em;
  margin-top: 80px;
  margin-bottom: 1rem;
  border-radius: 1.25rem;
  background-color: var(--color-background-progress-bar);
  color: var(--color-text-category);
}

.inputAlfaNumeric {
  position: absolute;
  margin-top: 48px !important;
  width: 64% !important;
  margin-left: 14%;
  height: 132px !important;
  background: #F8F8F8 0% 0% no-repeat padding-box !important;
  border: 2px solid #C9C9C9 !important;
  border-radius: 32px !important;
  opacity: 1 !important;
}

.key-1 {
  grid-area: key-1;
}

.key-2 {
  grid-area: key-2;
}

.key-3 {
  grid-area: key-3;
}

.key-4 {
  grid-area: key-4;
}

.key-5 {
  grid-area: key-5;
}

.key-6 {
  grid-area: key-6;
}

.key-7 {
  grid-area: key-7;
}

.key-8 {
  grid-area: key-8;
}

.key-9 {
  grid-area: key-9;
}

.key-0 {
  grid-area: key-0;
}

.key-limpar {
  grid-area: key-limpar;
}

.key-c {
  grid-area: key-c;
}

.key-add {
  grid-area: key-add;
}

.keyAlfabutton {
  width: 65px;
  margin-left: 7px;
  margin-bottom: 11px;
  font-size: 40px !important;
}

.keys2 {
  margin-left: 35px;
}

.keys3 {
  margin-left: 45px;
}

.keys4 {
  margin-left: 10px;
}

.deleteKeys {
  font-size: 36px !important;
  width: 100px !important;
  background: #FFD2B4 0% 0% no-repeat padding-box;
  border: 1px solid #FFD2B4 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.numericKeypadAlfa {
  width: 95% !important;
  display: table !important;
  grid-template-columns: repeat(3, 1fr);
  margin: -27px auto 12em !important;
  grid-gap: 0.5em;
  height: 410px;
  border: none !important;
  padding: 330px 0px 0px 30px !important;
  border-radius: 20px;
  background-color: var(--color-keyboard-input);
}

.numericKeypadAlfa button {
  padding: 15px !important;
  border-radius: 2px !important;
}

.numericKeypad {
  display: grid;
  grid-template-areas:
    "key-1 key-2 key-3"
    "key-4 key-5 key-6"
    "key-7 key-8 key-9"
    "key-limpar key-0 key-c"
    "key-add key-add key-add";
  grid-template-columns: repeat(3, 1fr);
  width: 70%;
  margin: -12px auto 5em;
  grid-gap: 1em;
  padding: 2em;
  border-radius: 20px;
  background-color: var(--color-keyboard-input);
}

.numericKeypad button,
.keyboard-login button {
  outline: none;
  font-size: 40px;
  color: var(--color-background-keyboard);
  padding: 0.5em;
  border: 3px solid var(--color-keyboard-input);
  background-color: var(--color-keyboard-background);
  border-radius: 20px;
}

.numericKeypad .key-limpar {
  font-size: 30px;
}

.numericKeypad .key-add {
  color: var(--color-title-category);
  background-color: var(--color-title-secondary);
  border: 3px solid var(--color-title-secondary);
}

.keyboard-login {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5em;
  width: 65%;
  padding: 1em;
  margin: 60px auto;
  border-radius: 20px;
  background-color: var(--color-keyboard-input);
}

.keyboard-login .key {
  outline: none;
  width: 100%;
}

.keyboard-login .key[value="limpar"] {
  font-size: 2rem;
}

.modalCupomAdd {
  position: absolute;
  z-index: 20;
  top: 1715px;
  left: 25px;
  width: 1030px;
  height: 180px;
  /* UI Properties */
  background: var(---1e0080) 0% 0% no-repeat padding-box;
  background: #1E0080 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #0000008D;
  border-radius: 20px;
  opacity: 1;
}

.modalCupomAddSpan{
  font-size: 44px;
  color: #FFFFFF;
  opacity: 1;
  float: left;
  margin-top: 64px;
  margin-left: 242px;
}

.icCupomAdd {
  margin-top: 66px;
  position: absolute;
  margin-left: 174px;
}
/* End NumericKeypad */

/* LOGIN */
.login {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  font-size: 3rem;
}

.login .keyboard-login {
  margin-top: 30px;
  margin-bottom: 40px;
}

.login label {
  text-align: left;
  margin-bottom: 0.2em;
}

.login select,
.login input {
  box-sizing: border-box;
  font-size: 65px;
  height: 9.563rem;
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 20px;
}

.login select {
  margin-bottom: 3rem;
}

.login button {
  outline: none;
  width: 50%;
  margin: 0 auto;
}
/* END LOGIN */

/* PLUGIN simple-keyboard */
.simple-keyboard {
  color: #000;
  font-size: 2rem;
}

.ConfigDevice {
  margin-top: 18%;
  margin-left: 12%;
}

.ConfigInputDevice {
  height: 60px;
  width: 568px;
}

.selectedTerminalId {
    width: 572px;
    height: 70px;
    font-size: 30px;
}

.ConfigLabelDevice {
  font-size: 30px;
}

.ConfigLabelDeviceButton {
  margin-top: 40px;
  font-size: 30px !important;
  color: #ffffff;
  background-color: #100316;
  width: 75%;
  margin-left: 22px;
}

.App.keyboard {
  width: 80%;
  margin: 15.25em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App.keyboard input {
  font-size: 2.9rem;
}

.keyboard p + input {
  margin-top: 0;
}

p.label {
  color: var(--color-text-category);
  font-size: 33px;
  text-align: center;
}

.App.keyboard .hg-theme-default .hg-button {
  font-family: var(--font-family-main), "Bib's Sans Bold", "Lucida Grande", Sans-Serif;
  height: 90px;
  font-size: 40px;
  background-color: var(--color-background);
  color: var(--color-background-primary) !important;
  min-width: 70px !important;
  border: 10px solid var(--color-keyboard-input);
  border: 0px solid transparent!important;
  border-right: 0;
  margin-right: 10px !important;
  min-width: auto !important;
  margin-bottom: 5px !important;
  border-right: 0px solid #006d64;
}

/* END PLUGIN */

/* PAYAMENT */
.payment-page {
  overflow-y: scroll !important;
}

.payament-list {
  height: 65vh;
  display: grid;
  grid-template-columns: 380px 380px;
  /* Is property [grid-gap] has obsolete. */
  grid-gap: 3em;
  padding: 0 70px;
  margin: 6.25em 0;
}

.payament-list .card {
  box-sizing: border-box;
  max-width: 380px;
  height: 360px;
  padding: 50px 0;
  margin: 0;
}

.payament-list .card-img {
  width: initial;
}

.payament-list .card-title {
  font-size: 1.5625rem;
  margin-top: 30px;
}
/* END PAYAMENT */

/* CUPOM */
.cupom-template * {
  margin: 0;
}

.cupom-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  color: var(--color-title-secondary);
  text-align: center;
  height: 100vh;
  padding: 10em 2em 2em;
  background-color: var(--color-background-primary);
}

.cupom-template .name {
  font-size: 5.875rem;
  text-transform: uppercase;
  margin-top: 3.6em;
}

.cupom-template .points {
  color: var(--color-background-primary);
  background-color: var(--color-button-secondary);
  font-size: 33px;
  text-transform: uppercase;
  min-width: 774px;
  border: 2px solid var(--color-button-secondary);
  padding: 35px;
  margin: 20px 0;
  border-radius: 20px;
  box-sizing: border-box;
}

.cupom-template .points strong {
  color: var(--color-background-primary);
}

.cupom-template .message {
  text-transform: uppercase;
}

.cupom-template .message {
  font-size: 3.75rem;
}

.cupom-template .warn-cupom {
  color: var(--color-background-progress-bar);
  font-size: 2.188rem;
  margin-bottom: 1.0em;
}

.cupom-template .code {
  font-size: 16.25rem;
  color: var(--color-title-category);
  width: 774px;
  border-radius: 0.154em;
  background-color: var(--color-background-progress-bar);
}

.cupom-template .promotion {
  height: 30vh;
  width: 100vw;
  border-radius: 2.5em 2.5em 0 0;
  position: fixed;
  bottom: -90px;
  background-color: var(--color-background-light);
}
.pgtocaixa {
  margin-top: 10px;
}

.setadinheiro{
  margin-right: 25px;
  margin-bottom: 35px;
}

.logosQrlinx {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  padding: 16px;
  margin-top: 17px;
  margin-left: 16px;
  max-width: 120px;
  max-height: 40px;
  flex-grow: 1;
  flex-basis: 120px;
}

.QrlinxPayment {
  background-color: #fff;
  border-radius: 1.875rem;
  padding-right: 20px;
  padding-left: 20px;
  width: 192px;
  margin-left: 15px;
}

.descriptionCampgning {
  width: 206px;
  font-size: 19px;
  background-color: #640027;
  padding: 10px;
  margin-left: 280px;
  margin-top: -13px;
}

.promotion img {
  position: relative;
  top: 0;
}
/* END CUPOM */

/* CAROUSEL */
.carousel {
  height: 100vh;
}

.carousel div {
  height: inherit;
}

.carousel.fade button {
  text-transform: uppercase;
  outline: none;
  font-size: 43px;
  width: 822px;
  position: fixed;
  bottom: 8em;
  transform: translate(-50%, 50%);
  left: 50%;
}

.cpfFidelity {
  margin-top: 2px !important;
  height: 200px !important;
  border-radius: 20px !important;
  opacity: 1 !important;
  text-align: left !important;
}

.fade {
  animation: fade 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
/* END CAROUSEL */

.habibs {
  margin-top: 184px;
  padding: 2em 1em !important;
  border-radius: 20px !important;
  background-color: var(--color-keyboard-input);
}

.deviceConfig {
  /* margin-top: 184px; */
  padding: 2em 1em !important;
  border-radius: 20px !important;
  background-color: var(--color-keyboard-input) !important;
}

.App.keyboard .hg-theme-default .hg-row .hg-button-container,
.App.keyboard .hg-theme-default .hg-row .hg-button:not(:last-child) {
  margin-right: 0;
}

.App.keyboard .hg-theme-default .hg-row:nth-child(2) {
  /* padding: 0 5.9%; */
}

.App.keyboard .hg-theme-default .hg-row:nth-child(3) {
  /* padding: 0 9%; */
}

.App.keyboard .hg-theme-default .hg-row:nth-child(4) .hg-button {
  width: 85%;
}

/* loader */
#wrapper-loader {
  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 100%;
  height: 250px;
}

#wrapper-loader p {
  font-size: 2rem;
}

#loader {
  margin: 0 auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Modal balckout */
.blackout {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.8);
}

.filters {
  box-sizing: border-box;
  color: var(--color-title-category);
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  width: 90%;
  margin: 1em auto 0;
  padding: 1em 1em 2em;
  border-radius: 20px;
  background-color: var(--color-background-progress-bar);
}

.filters-tags {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.filters .tag {
  display: inline-block;
  color: var(--color-title-category);
  font-size: 25px;
  font-weight: 700;
  border: 2px solid var(--color-title-category);
  border-radius: 999px;
  padding: 1em 1em;
}

.filters .selected {
  background: var(--color-title-category);
  display: inline-block;
  color: var(--color-background-light);
  font-size: 25px;
  font-weight: 700;
  border: 2px solid var(--color-title-category);
  border-radius: 999px;
  padding: 1em 1em;
}

.arrow-up,
.arrow-down {
  display: inline-block;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-title-category);
  margin: 0 0.5em;
}

.arrow-left {
  font-size: 2.5rem;
  position: absolute;
  top: 32px;
  left: 20px;
  color: var(--color-title-category);
}

.highlight-add-item {
  color: var(--color-additem);
  text-transform: uppercase;
  line-height: 60px;
  width: 100%;
  border-radius: 20px 20px 20px 0;
  position: fixed;
  bottom: 175px;
  left: 0;
  z-index: 12;
  background: var(--color-title-secondary);
}

.highlight-add-item:after {
  content: "";
  height: 40px;
  width: 25px;
  position: absolute;
  bottom: -40px;
  border-radius: 20px 0 0 0;
  left: -5px;
}

.added-item {
  font-size: 23px;
  padding-right: 200px;
  padding-left: 60px;
  z-index: 999;
}

.added-item span {
  color: inherit;
  font-size: 17px;
  padding: 0.1em 1.5em;
  border-radius: 50px;
  position: absolute;
  right: 55px;
  background: var(--color-background-light);
}

.sticky {
  z-index: 2;
  min-height: 237px;
  text-align: center;
  background-color: var(--color-background);
  flex-shrink: 0;
}

/* Flavor Select List */

.flavor-select-list {
  padding: 2em 1.5em 2em;
  margin-bottom: 0;
  border-radius: 1.25em;
  /* max-height: 20vh;
  min-height: 20vh; */
  overflow-y: auto;
}

.flavor-select-list li {
  border-bottom: 2px solid #f3f1f1;
}

.flavor-select-list li:last-child {
  border-bottom: none;
}

.flavor-select-list-image {
  height: 70px;
  width: 110px;
  font-weight: 100;
  border-radius: 20px;
}

.flavor-select-list-action button {
  color: var(--color-background-light);
  padding: 0.1em 0.7em;
  background-color: var(--color-background-cart);
  border-radius: 20px;
  outline: none;
}

.flavor-select-list-action .quantity {
  display: inline-block;
  font-size: 2.375rem;
  font-weight: 700;
  min-width: 60px;
  padding: 0 0.3em;
  text-align: center;
}

.flavor-select-list-item {
  font-size: 1.25rem;
  text-align: left;
  text-transform: uppercase;
  color: var(--color-text-flavor-list);
}

.flavor-select-list-item-name {
  margin: 0.3em;
}

.TimerCouldown {
  color: var(--color-background-progress-bar);
  margin-left: 32%;
  margin-top: 14%;
  position: fixed;
  font-size: 60px;
}

.TimerCouldownPdv {
  font-size: 45px;
}

.background-flavor-select-list {
  color: var(--color-text-category);
  background-color: var(--color-background-light);
}

.flavor-select-list-info {
  padding: 1em 1em 0;
  display: grid;
  grid-template-columns: 7fr 1fr auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flavor-select-list-info > strong {
  color: var(--color-text-flavor-list);
}

.flavor-select-list-info .proceed {
  min-width: max-content;
  color: var(--color-fidelity);
}

.flavor-select-list-info .no-proceed {
  min-width: max-content;
  color: var(--color-title-category);
}

.flavor-select-list-info .required {
  margin-left: 0.8em;
  background: var(--color-title-category);
  color: var(--color-background-light);
  text-transform: uppercase;
  padding: 0.2em 0.7em;
  border-radius: 0.4em;
}

.flavor-select-list-info .no-required {
  margin-left: 0.8em;
  background: var(--color-fidelity);
  color: var(--color-background-light);
  text-transform: uppercase;
  padding: 0.2em 0.7em;
  border-radius: 0.4em;
}

.cancelTEF button {
  position: relative;
  top: -120px;
}

.cancelQRCode button {
  position: relative;
  top: 50px;
}

.hg-button.hg-functionBtn.hg-button-bksp span {
  font-size: 0px;
}

.hg-button.hg-functionBtn.hg-button-bksp span:after {
  content: "←";
  font-size: 55px;
}

.prismaImage {
  background: url(./assets/prisma-rex.png) 50% 50% no-repeat;
  margin: 35px auto;
}

.ragazo-mais {
  margin-top: -250px;
}

.title-points {
  color: var(--color-background-light);
  font-size: 75px;
  font-weight: 700;

  text-transform: none;
  word-wrap: break-word;
  margin: 0;
}

.box-points {
  text-align: center;
  width: 82%;
  border: 3px solid var(--color-box-points);
  padding: 150px 50px;
  margin: 114px auto;
  border-radius: 30px;
}

.text-point,
.points-value {
  margin: 12px 0;
  /* margin-bottom: -30px; */
}

.text-point {
  font-size: 50px;
  margin-bottom: -15px;
}

.box-campaign {
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 94%;
  position: absolute;
  bottom: 10%;
  overflow: auto;
}

.content-campaign {
  overflow: auto;
  border-radius: 20px;
  border: 2px solid var(--color-box-shadow-primary);
  max-height: 350px;
  background-color: var(--color-background-light);
}

.item-campaign {
  display: grid;
  grid-template-columns: 15% 85%;
  background-color: var(--color-background-light);
  border: 1px solid var(--linha-borda);
  border-bottom: 1px solid #EAEAEA;
  opacity: 1;
}

.item-campaign .icon {
  font-size: 46px;
  text-align: center;
  line-height: 0;
  align-items: center;
  color: var(--color-text-category);
  display: flex;
  justify-content: center;
}

.text-campaign{
  color: var(--color-text-category);
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  margin: 0.35em 0;
}

.item-text-campaign{
  font-size: 25px;
  color: var(--color-text-category);
}

.points-value {
  color: var(--color-title-points);
  font-size: 110px;
}

.serverError {
  width: 100%;
  padding: 0.8em;
  position: fixed;
  top: 0;
  right: 0;
  animation-name: messageserver;
  animation-duration: 1s;
  background-color: var(--color-keyboard-input);
}

.serverError h1,
.serverError h2 {
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  border-color: #f5c6cb;
}

.btn-serverError {
  color: #fff;
  margin: 0.5em 0;
  background-color: var(--color-subtitle);
}

@keyframes messageserver {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

.fidelityPoints {
  font-size: 60px;
  font-weight: 700;
  margin-left: 30px;
  right: 6px;
}

.fidelityPoints::after {
  content: " pontos";
  font-size: 20px;
  font-weight: 400;
  position: relative;
  top: -10px;
}

.category-title-fidelityPoints {
  color: var(--color--background-banner);
  text-align: left;
}

.category-title-habibers {
  color: var(--color--background-banner);
  text-align: center;
}

.resgate-cupom {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  position: relative;
}

.resgate-cupom img {
  width: 100%;
  height: auto;
}

.resgate-cupom button {
  outline: none;
  font-weight: bold;
  text-transform: uppercase;
  width: 90%;
  bottom: 18%;
  position: absolute;
  background-color: var(--color--background-banner);
  color: var(--color-button-banner);
}

.hide {
  display: none;
}

.tag-fidelity {
  display: inline-block;
  color: var(--color-background-modal);
  text-transform: uppercase;
  border-radius: 20px;
  padding: 0.5em 1em;
  background-color: var(--color-button-secondary);
}

.keyboard.resgate {
  height: 100vh;
  margin: 1px auto 225px;
  /* margin-left: 200px; */
}

.addAlfaNumeric {
  margin-left: 218px;
}

.keyboard.resgate .numericKeypad {
  background-color: var(--color-background-light);
  border: 2px solid var(--color-title-secondary);
}

.keyboard.resgate .numericKeypad button {
  outline: none;
  background-color: var(--color-button-resgate);
  border: none !important;
  color: var(--color-background-primary);
}

.keyboard.resgate .key-add {
  color: var(--color-title-category) !important;
  background-color: var(--color-title-secondary);
  border-radius: 999px;
}

.keyboard.resgate .input {
  direction: RTL;
  color: var(--color-title-category);
  border: 2px solid var(--color-title-secondary);
}

.keyboard.fidelidade .key-add {
  /*background-color: var(--color-subtitle);*/
  border: 3px solid var(--color-background-keyboard);
  color: var(--color-title-category);
}

.keyboard.fidelidade .numericKeypad {
  background-color: var(--color-background-keyboard);
}

.keyboard.fidelidade .numericKeypad button {
  outline: none;
  border: 3px solid var(--color-background-keyboard);
}

.keyboard.fidelidade .key-add {
  border: 3px solid var(--color-title-secondary) !important;
}
.modal.automatic-reset button {
  text-transform: uppercase;
  margin: 1em 1em !important;
  width: 85%;
}

.hasIMG img {
  min-height: 380px;
  width: 700px;
  margin-bottom: -15px;
}

.image-position {
  min-height: 380px;
  width: 70%;
  margin-bottom: -15px;
}

.modal_blackout {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 20;
  background: rgba(0, 0, 0, 0.82);
}

.modal_resgateCupom {
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  width: 80%;
  height: 50vh;
  padding: 54px;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-background-modal);
}

.modal_resgateCupom__title {
  color: var(--color-title-category);
  font-family: inherit;
  font-size: 60px;
}

.modal_resgateCupom__product {
  color: var(--color-text-category);
  font-size: 25px;
}

.modal_resgateCupom__value {
  color: var(--color-title-category);
  font-size: 25px;
}

.modal_resgateCupom .buttons {
  width: inherit;
  margin: 0 auto;
}

.modal_resgateCupom .buttons button {
  color: var(--color-title-category);
  width: 45%;
  margin: 0px 10px;
  outline: none;
}

.fidconfirm .leitora-confirm {
  width: 360px;
  height: 230px;
  margin-top: 90px;
}

.teste123 {
  width: 47%;
  height: 275px;
  float: left;
  margin-left: 22px;
}

.teste1234 {
  border-radius: 6px;
}

.teste12345{
  border-radius: 5px !important;
}

.fidconfirm .titulo {
  color: var(--color-title-secondary);
  font-size: 60px;
  margin: 100px auto;
  text-align: center;
  max-width: 600px;
}

.fidconfirm .box {
  width: 800px;
  height: 190px;
  background-color: var(--color-background-progress-bar);
  border: 4px solid var(--color-background-card-primary);
  border-radius: 20px;
  margin: 0 auto;
  color: var(--color-background-card-primary);
  box-sizing: border-box;
  padding: 45px 80px;
  font-size: 30px;
  margin-top: 190px;
}

.box strong {
  font-size: 45px;
}

.box p {
  margin: -6px;
}

.keyboard p {
  color: var(--color-button-banner);
}

.keyboard.resgate p {
  color: var(--color-text-category);
  margin-top: 3px;
}

.backbutton-insertcupom-container {
    z-index: 2;
    bottom: 0%;
    width: 94%;
    margin-top: 64px;
    justify-content: center;
}

.backbutton-insertcupom-container button {
  height: 126px;
  color: var(--color-title-category);
  width: 47% !important;
}

.backbutton-insertcupom-container button.done-button {
  height: 126px;
  color: var(--color-title-category);
  background-color: var(--color-title-secondary) !important;
  margin-left: 40px;
}

.background-error-screen {
  position: absolute;
  background: radial-gradient(circle,#613664 0%,#2e0037 100%);
  width: 100%;
  height: 1920px;
}

.image-error-screen {
    width: 600px;
    margin-left: 23%;
}

.text-error-screen {
  text-align: center;
  margin-bottom: 37%;
  margin-top: -33%;
  color: white;
}

.keyboard.prisma .numericKeypad {
  margin-top: -20px;
}

.prisma .title {
  margin-top: 1em;
  text-transform: none;
}

.prisma img {
  margin-top: -40px;
  margin-bottom: -130px;
  width: 280px;
}

.pager img {
  margin-top: -40px;
  margin-bottom: -110px;
  width: 415px;
}

.step-buttons {
  outline: none;
  display: flex;
  justify-content: space-between;
}

.car {
  width: 45%;
  margin-bottom: -300px;
}

.input-car {
  margin-bottom: -50px !important;
}

.withdrawn-cards {
  margin-top: 200px;
}

.qrWallets {
  width: 200px;
  height: 55px;
}

.textQrCode {
  font-size: 32px;
}

.totalQrcode {
  position: fixed;
  font-size: 50px;
  margin-top: 270px;
  margin-left: -23%;
  color: var(--color-title-secondary);
}

.check-color {
  color: #87d287;
  margin-left: 14px;
  font-size: 65px;
}

.textPaymentMethod {
  text-transform: none !important;
}

.borderClassPayment {
  border: solid 1px black;
  padding: 20px;
  margin-bottom: 60px;
}

.borderClassPayment > div div {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.button-btnsecondary-off {
  display: none !important;
}

.flags-language {
  position: absolute;
}
.flags-container {
  display: flex;
  position: absolute;
  z-index: 9999;
}
.flag {
  cursor: pointer;
  width: 78px;
  height: 78px;
  margin: 10px;
  filter: grayscale(1);
}
.flag:hover {
  filter: grayscale(0.5);
}
.selected {
  filter: grayscale(0);
}
.selected:hover {
  filter: grayscale(0);
}

.config__device {
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.config__device .form__group {
  width: 100%;
  margin-bottom: 0.7rem;
  font-size: 2rem;
  height: 105px;
}

.config__device .form__group:last-child {
  margin-bottom: 0;
}

.config__device .form__group label.text {
  display: block;
  width: 100%;
}

.config__device .form__group select,
.config__device .form__group input {
  font-size: 1.5rem;
  width: 42%;
  font-family: monospace;
  margin-top: 18px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-out;
}

.config__device .form__group label.toggle {
  position: relative;
  display: inline-block;
  margin-top: 16px;
  height: 34px;
  width: 60px;
}

.config__device .form__group label.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.config__device .form__group label.toggle .slider {
  position: absolute;
  border: 2px solid black;
  border-radius: 20px;
  transition: .4s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 80px;
}

.config__device .form__group label.toggle .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 7px;
  bottom: 6px;
  background-color: black;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.config__device .form__group label.toggle input:checked + .slider {
  background-color: #2196F3;
  border: 2px solid #2196F3 !important;
}

.textToggle {
  font-size: 20px;
  margin-left: 100px;
  font-family: monospace;
}

.config__device .form__group label.toggle input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.config__device .form__group label.toggle input:checked + .slider:before {
  -webkit-transform: translateX(44px);
  -ms-transform: translateX(44px);
  transform: translateX(44px);
  background-color: white;

}

.config__device button[type=submit] {
  background-color: #1E0080;
  color: #fff;
  width: 50%;
  margin-top: 50px;
  align-self: center;
}
